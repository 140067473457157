<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Tabs">
                    <template v-slot:description>
                        <div>import { HbTabs } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Tabs Example + Code" class="mt-4 mb-6">
            <div class="pb-4 px-6 hb-cloud-lighter">
                <hb-header full :divider="false">
                    <HbHeaderInfo
                        type="contact"
                        title="John Smith"
                        is-military
                        status="gate lockout"
                        phone="+1 (310) 234-2354"
                        phone-is-verified
                        email="johnsmith@somewebsite.coms"
                        email-is-verified
                        address="100 Main Street, Los Angeles 90039"
                        multiple-access-codes
                    />
                </hb-header>
                <hb-header one-action :padding="false" align-top>
                    <template v-slot:left>
                        <hb-tabs v-model="activeTab">
                            <v-tab
                                v-for="(tab, i) in tabs"
                                :key="'tab' + i"
                                :ripple="false"
                            >
                                Space {{ tab.number }}
                            </v-tab>
                        </hb-tabs>
                    </template>
                    <template v-slot:right>
                        <hb-btn class="mr-1" :class="{'mr-3' : $vuetify.breakpoint.xs}" icon tooltip="Sidebar">mdi-table-actions-custom-1</hb-btn>
                    </template>
                </hb-header>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-tabs v-model="activeTab">
    &lt;v-tab
        v-for="(tab, i) in tabs"
        :key="'tab' + i"
        :ripple="false"
    >
        Space <span v-pre>{{ tab.number }}</span>
    &lt;/v-tab>
&lt;/hb-tabs>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="All Other Props, Slots, &amp; Events" class="my-6 pb-1">
            <hb-data-table
                :headers="vuetifyHeaders"
                :items="vuetifyItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemTabs",
        data: function() {
            return {
                contact: { "id": "123213213", "salutation": "", "first": "Bellatrix", "middle": "", "last": "Lestrange", "suffix": "", "email": "bellatrix@lestrange123.com", "company": "", "dob": null, "ssn": null, "gender": null, "status": "Current", "driver_license": null, "active_military": 1, "military_branch": null, "source": null, "Phones": [ { "id": "1VBqVd0vwy", "type": "Cell", "phone": "2225030275", "sms": true } ], "Addresses": [ { "id": "123123213421", "type": "Home", "Address": { "address": "123121 Main Street", "address2": "A", "city": "El Paso", "state": "CA", "neighborhood": null, "zip": "92604", "country": null, "lat": null, "lng": null } } ], "Relationships": [] },
                activeTab: '',
                tabs: [ 
                    { number: '200' },
                    { number: '233' },
                    { number: '262' },
                    { number: '263' },
                    { number: '264' },
                    { number: '265' },
                    { number: '266' },
                    { number: '267' },
                    { number: '500' },
                    { number: '501' }
                ],
                propHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'background-color', type: 'string', default: 'undefined', description: 'Changes the default background color. The default background color is #F9FAFB and usually should not be changed.' },
                ],
                vuetifyHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                vuetifyItems: [
                    { name: 'all vuetify props, slots, & events', description: 'All the same vuetify props, slots, & events for "<v-tabs>" can be used for "<hb-tabs>". See the vuetify documentation link in the next column for the list of available props, slots, & events.', resource: 'https://v2.vuetifyjs.com/en/api/v-tab/' },
                ],
            };
        },
    }
</script>

<style scoped>

</style>